"use strict";

const $ = require('jquery');
require('jstree');
const CryptoUtil = require("./CryptoUtil.js");
const DataUtil = require("./DataUtil.js");
const Util = require("./Util.js");
const Constants = require("./Constants.js");
const Cloud = require("./Cloud.js");
const Icons = require("./Icons.js");
const AccServices = require("./AccServices.js");
const AccUtil = require("./AccUtil.js");

// Makes these functions and objects available in the browser
window.Constants = Constants;
window.DataUtil = DataUtil;
window.CryptoUtil = CryptoUtil;
window.Util = Util;
window.Cloud = Cloud;
window.Icons = Icons;
window.AccServices = AccServices;
window.AccUtil = AccUtil;
window.$ = $;
window.jQuery = $;
